import ExecAPI from 'appRoot/api/api';
import EventBus from 'appRoot/scripts/EventBus';

export function GetAllProductImages()
{
	return ExecAPI("CMSUserData/GetAllProductImages");
}
export function GetCMSProductRoot(productRootId)
{
	return ExecAPI("CMSUserData/GetCMSProductRoot", { ProductRootId: productRootId });
}
export function UpdateCMSProductRoot(original, updated)
{
	return ExecAPI("CMSUserData/UpdateCMSProductRoot", { original: original, updated: updated });
}
export function GetCMSProductRoots()
{
	return ExecAPI("CMSUserData/GetCMSProductRoots");
}
export function GetCMSProductRevision(productRevisionId)
{
	return ExecAPI("CMSUserData/GetCMSProductRevision", { ProductRevisionId: productRevisionId });
}
export function GetCMSProductRevisions(productRootId)
{
	return ExecAPI("CMSUserData/GetCMSProductRevisions", { ProductRootId: productRootId });
}
export function CreateCMSProductRevisionForProductRoot(productRootId, productRevisionId)
{
	return ExecAPI("CMSUserData/CreateCMSProductRevisionForProductRoot", { ProductRootId: productRootId, ProductRevisionId: productRevisionId });
}
export function UpdateCMSProductRevision(original, updated, imageData)
{
	return ExecAPI("CMSUserData/UpdateCMSProductRevision", { original: original, updated: updated, imageData: imageData });
}
export function DeleteCMSProductRevision(productRevisionId)
{
	return ExecAPI("CMSUserData/DeleteCMSProductRevision", { ProductRevisionId: productRevisionId });
}
export function DoCMSFirstTimeSetup()
{
	return ExecAPI("CMSUserData/DoCMSFirstTimeSetup");
}
export function DoCMSDbBackup()
{
	return ExecAPI("CMSUserData/DoCMSDbBackup");
}
export function CMSSyncNow()
{
	return ExecAPI("CMSUserData/CMSSyncNow");
}
export function CMSBeginArchiving(productRevisionId, stageAfterSuccessfulArchival)
{
	return ExecAPI("CMSUserData/CMSBeginArchiving", { ProductRevisionId: productRevisionId, StageAfterSuccessfulArchival: stageAfterSuccessfulArchival });
}
export function CMSCancelArchiving(productRevisionId)
{
	return ExecAPI("CMSUserData/CMSCancelArchiving", { ProductRevisionId: productRevisionId });
}
export function CMSDeleteArchive(productRevisionId)
{
	return ExecAPI("CMSUserData/CMSDeleteArchive", { ProductRevisionId: productRevisionId });
}
export function PageChangeTracker(productRootId, productRevisionId, editing, activeTab)
{
	return ExecAPI("CMSUserData/PageChangeTracker", { browserInstanceId: EventBus.browserInstanceId, productRootId, productRevisionId, editing, activeTab });
}
export function ChangeCMSProductRevisionLifecycleState(productRevision, newState)
{
	return ExecAPI("CMSUserData/ChangeCMSProductRevisionLifecycleState", { productRevision: productRevision, newState: newState });
}
export function GetCmsProductInformation()
{
	return ExecAPI("CMSUserData/GetCmsProductInformation");
}
export function GetContentSyncAgentStatus(agentId)
{
	return ExecAPI("CMSUserData/GetContentSyncAgentStatus", { AgentId: agentId });
}
export function GetOnlineAgentsNotStaged(productRevisionId)
{
	return ExecAPI("CMSUserData/GetOnlineAgentsNotStaged", { ProductRevisionId: productRevisionId });
}
export function ValidateAllCMSProductInformation()
{
	return ExecAPI("CMSUserData/ValidateAllCMSProductInformation");
}
export function CMSCreateNewProductRoot(internalNameHtml)
{
	return ExecAPI("CMSUserData/CMSCreateNewProductRoot", { internalNameHtml: internalNameHtml });
}
export function CMSGetLoeData(ProductRevisionId)
{
	return ExecAPI("CMSUserData/CMSGetLoeData", { ProductRevisionId });
}
export function CMSCreateLoeProduct(ProductRevisionId, Overwrite)
{
	return ExecAPI("CMSUserData/CMSCreateLoeProduct", { ProductRevisionId, Overwrite });
}
/**
 * Sets the given Revision Automation Flags.
 * @param {Number} ProductRevisionId ProductRevisionId
 * @param {Boolean} StageAfterSuccessfulArchival If not null, the StageAfterSuccessfulArchival flag is assigned this value.
 * @param {Boolean} LiveAfterStagingOnlineCompleted If not null, the LiveAfterStagingOnlineCompleted flag is assigned this value.
 */
export function CMSSetRevisionAutomationFlags(ProductRevisionId, StageAfterSuccessfulArchival, LiveAfterStagingOnlineCompleted)
{
	return ExecAPI("CMSUserData/CMSSetRevisionAutomationFlags", { ProductRevisionId, StageAfterSuccessfulArchival, LiveAfterStagingOnlineCompleted });
}
export function GetCMSSyncData()
{
	return ExecAPI("CMSUserData/GetCMSSyncData");
}
export function CMSAddSimplePublisherOption(simplePublisherName)
{
	return ExecAPI("CMSUserData/CMSAddSimplePublisherOption", { simplePublisherName: simplePublisherName });
}
export function CMSDeleteSimplePublisherOption(simplePublisherName)
{
	return ExecAPI("CMSUserData/CMSDeleteSimplePublisherOption", { simplePublisherName: simplePublisherName });
}
export function ForceFullSync()
{
	return ExecAPI("CMSUserData/ForceFullSync");
}
export function CMSStarProductRoot(Id, Star)
{
	return ExecAPI("CMSUserData/CMSStarProductRoot", { Id: Id, Star: Star });
}
export function CMSStarProductRevision(Id, Star)
{
	return ExecAPI("CMSUserData/CMSStarProductRevision", { Id: Id, Star: Star });
}
export function CMSGetProductRootHistory(ProductRootId)
{
	return ExecAPI("CMSUserData/CMSGetProductRootHistory", { ProductRootId });
}
export function CMSGetProductRevisionHistory(ProductRevisionId)
{
	return ExecAPI("CMSUserData/CMSGetProductRevisionHistory", { ProductRevisionId });
}
export function GetLiveCMSProductRevisionIdFromFxid(fxid)
{
	return ExecAPI("CMSUserData/GetLiveCMSProductRevisionIdFromFxid", { fxid });
}