<template>
	<div :class="{ pageRoot: true, inTrash: productRoot && productRoot.Trash }">
		<div v-if="error" class="error SidePadding">
			<h2>CMS Product Revision {{productRevisionId}}</h2>
			<p>{{error}}</p>
		</div>
		<div v-else-if="loading && !productRevision" class="loading SidePadding">
			<h2>CMS Product Revision {{productRevisionId}}</h2>
			<div class="center"><ScaleLoader /> Loading…</div>
		</div>
		<SavableRegion v-else-if="productRevision" :saving="saving || loading" :text="loading ? 'Updating' : 'Saving'">
			<div class="stickyTop" :class="stickyTopDynClasses">
				<router-link :to="GetRouteToProductRoot(productRoot)" class="backButton" title="Open Root Product"><vsvg sprite="expand_more" /></router-link>
				<h3 class="name"><img :src="starImgProductRevisionMap[productRevisionId]" alt="star" class="product-star" role="button" tabindex="0" @click="toggleStarProductRevision(productRevision)" @keypress.enter.space.prevent="toggleStarProductRevision(productRevision)" /><span v-html="productRevision.RevisionNameHtml"></span></h3>
				<button class="SaveButton" :class="{ disabled: !hasChanges }" @click="saveRevision">Save</button>
				<div class="FloatingBelow">
					<a role="button" tabindex="0" @click="OpenCmsProductLinksDialog" @keypress.enter.space.prevent="OpenCmsProductLinksDialog" class="alwaysUnvisited">fxid {{productRoot.TaylorProductId}}</a>
					{{currentUsersHtml ? '-' : ''}}
					<vsvg v-if="pageStateUnavailable" sprite="cable" class="page_state_unavailable_img" title="This icon means the current page state could not be loaded. Some status indicators may be stale." />
					<span v-html="currentUsersHtml"></span>
					<div v-if="recordChangedServerside">Record Changed Serverside!</div>
				</div>
			</div>
			<div class="SidePadding">
				<div class="trashContainer" v-if="productRoot.Trash">
					<div class="trash"><vsvg sprite="delete" class="trash_img" /> This product is in the trash, making it inaccessible to customers.</div>
				</div>
				<CmsLifecycleState :productRevision="productRevision" @change="onLifecycleStateChange" />
				<div class="TopFields">
					<div class="EditField ProductImageEditField" :class="{ changed: imageChanged }">
						<div class="EditFieldTitle">
							Product Image <a :href="productImgSrc" :download="productRevisionImageDownloadName" class="openImgLink" title="download image"><vsvg sprite="upload" class="download_img" /></a>
							<vsvg v-if="imageChanged" sprite="replay" class="resetBtn" role="button" tabindex="0" @click="resetProductImage" @keypress.space.enter.prevent="resetProductImage" title="undo pending image change" />
						</div>
						<div class="EditFieldValue">
							<SelectImageControl @selected="newImageSelected"
												:imageSrc="productImgSrc"
												class="ProductImage"
												title="Upload a different image" />
						</div>
					</div>
					<div class="EditField FxMediaField" :class="{ assigned: archiveFileName }">
						<SavableRegion :saving="savingFlags || archivePendingOperation" :text="'Working'">
							<div class="EditFieldTitle">Fx/Media</div>
							<div class="EditFieldValue">
								<template v-if="archiveFileName">
									<div>{{archiveFileName}}{{archiveFileSize ? (" (" + archiveFileSize + ")") : ""}}</div>
									<div v-if="archiveFileDate">{{archiveFileDate}}</div>
									<template v-if="productRevision.LifecycleState == 'Upcoming'">
										<div><button class="buttonBarButton DeleteArchiveFileButton" @click="deleteArchiveFile()" title="Deletes the title archive assigned to this revision."><vsvg sprite="delete_forever" class="publish_img" /> <span>Delete</span></button></div>
									</template>
								</template>
								<template v-else>
									<template v-if="!productRoot.FileName">
										<div>ProductRoot needs a FileName assigned.</div>
									</template>
									<template v-else-if="archivePending">
										<div class="center"><ScaleLoader /></div>
										<div>Pending. See console below.</div>
										<div><button class="buttonBarButton CancelArchiveFileButton" @click="cancelArchiveFile()" title="Attempts to cancel creation of this archive."><vsvg sprite="cancel" class="publish_img" /> <span>Cancel</span></button></div>
									</template>
									<template v-else>
										<div>None Assigned</div>
										<template v-if="productRevision.LifecycleState == 'Upcoming'">
											<!--<div><button class="buttonBarButton AddArchiveFileButton" @click="addArchiveFile(false)" title="Assigns a new title archive to this revision built from Fx/Media currently on production_serv."><vsvg sprite="publish" class="publish_img" /> <span>Archive Only</span></button></div>-->
											<div>
												<slide-button class="slideToStage" ref="slideBtn1" key="sb1" :auto-width="false" :circle="true" :disabled="false"
															  :noanimate="false" :width="235" :height="28" :requireReleaseToComplete="true"
															  text="Archive &amp; Stage" success-text="starting…" @completed="addArchiveFile(true)"
															  title="Assigns a new title archive to this revision built from Fx/Media currently on production_serv, then puts this revision into the 'Staging' lifecycle state." />
											</div>
										</template>
									</template>
								</template>
								<div title="Automatically set this Revision to 'Live' when all Agents in the Online environment finish staging."><label><input type="checkbox" v-model="revisionAutomationFlags.LiveAfterStagingOnlineCompleted" /> Automatically go Live</label></div>
							</div>
						</SavableRegion>
					</div>
				</div>
				<CmsEditField v-model="productRevision.RevisionNameHtml" :orig="orig.RevisionNameHtml" name="Revision Name (Html Supported)" comment="©®™" />
				<CmsEditField v-model="productRevision.Marc21Record" :orig="orig.Marc21Record" name="Marc21Record" kind="textarea" :download="marc21RecordFileName" downloadType="application/marc; charset=utf-8" />
				<div class="EditField BibliographySection">
					<div class="EditFieldTitle">Bibliography</div>
					<div class="EditFieldValue">
						<BibliographyEditor :rev="productRevision" />
						<div v-if="isbnValidationResults" class="bibWarnings">
							<div v-for="(w, index) in isbnValidationResults" :key="'isbnWarning_' + index" class="bibWarning">{{w}}</div>
						</div>
						<div v-if="bibWarnings && bibWarnings.length" class="bibWarnings">
							<div v-for="(w, index) in bibWarnings" :key="'bibWarning_' + index" class="bibWarning">• {{w}}</div>
						</div>
					</div>
				</div>
				<div class="PublicSiteFields">
					<div class="PublicSiteFieldsLabel">Public Site Fields</div>
					<CmsEditField v-model="productRevision.PublicMetadata.DisplayTitle" :orig="orig.PublicMetadata.DisplayTitle" name="Display Title (optional)" title="The title to display on the public-facing title info page. If omitted, the displayed title will come from the Bibliography tab.  If that is also omitted, the title will come from the Information tab." />
					<CmsEditField v-model="productRevision.PublicMetadata.SubTitle" :orig="orig.PublicMetadata.SubTitle" name="Display Subtitle (optional)" title="The subtitle to display near/below the title on the public-facing title info page." />
					<CmsEditField v-model="productRevision.PublisherName" :orig="orig.PublisherName" name="Simple Publisher" kind="select" :options="publisherTypes" title="This is mainly for categorizing the title in 'Titles by Publisher' lists. Leave blank to assign no publisher." />
					<CmsEditField v-model="productRevision.PublicMetadata.Copyright" :orig="orig.PublicMetadata.Copyright" name="Display Copyright (optional)" title="The copyright text to use in the bibliography section on the public-facing title info page." />
					<CmsEditField v-model="productRevision.PublicMetadata.HtmlDescription" :orig="orig.PublicMetadata.HtmlDescription" name="Description (HTML Supported)" kind="ace_html" :multiLine="true" :html="true" title="Use full HTML formatting, including &lt;p&gt; tags." />
					<CmsEditField v-model="productRevision.PublicMetadata.MetadataDescription" :orig="orig.PublicMetadata.MetadataDescription" name="Metadata Description" kind="textarea" title="A shorter plain text description to be inserted in the description meta tag.  If not provided, a snippet of the full description may be used instead." />
					<CmsEditField v-model="productRevision.PublicMetadata.Keywords" :orig="orig.PublicMetadata.Keywords" name="Keywords" kind="textarea" />
					<CmsEditField v-model="productRevision.PublicMetadata.TitleInformationPageURLOverride" :orig="orig.PublicMetadata.TitleInformationPageURLOverride" name="Title Information Page URL Override (optional)" title="If specified, this URL will replace the dynamically generated title info page." />
				</div>
				<div class="CmsConfigFields">
					<div class="CmsConfigFieldsLabel">Internal Configuration (ProductRevision)</div>
					<CmsEditField v-model="productRevision.CmsConfig.Comment" :orig="orig.CmsConfig.Comment" name="Internal notes for this ProductRevision" title="A comment field for internal documentation or note-taking purposes.  For example, it could talk about the production schedule for this Revision or explain why something about it is unusual." kind="textarea" :multiLine="true" />
				</div>
				<div class="buttonBar">
					<button class="buttonBarButton" @click="onDeleteRevision()" title="We will ask for confirmation before deleting.">
						<img class="btnIconNoHover" :src="appPath + 'Images/delete.png'" alt="" role="presentation" />
						<span>Delete This Revision</span>
					</button>
					<button class="buttonBarButton" @click="showHistory = !showHistory">
						<vsvg sprite="history" /> {{showHistory ? 'Hide' : 'Show'}} History
					</button>
					<button class="buttonBarButton" @click="createLoeFromThisRevision()" :disabled="hasChanges">
						<vsvg sprite="add" /> Create LoE
					</button>
				</div>
				<div class="historySelectorContainer">
					<CmsProductHistorySelector v-if="showHistory" type="ProductRevision" :id="productRevisionId" @select="HistoryItemSelected" />
				</div>
			</div>
		</SavableRegion>
	</div>
</template>

<script>
	import { GetCMSProductRevision, UpdateCMSProductRevision, DeleteCMSProductRevision, CMSBeginArchiving, CMSCancelArchiving, CMSDeleteArchive, ChangeCMSProductRevisionLifecycleState, CMSSetRevisionAutomationFlags, GetLiveCMSProductRevisionIdFromFxid } from 'appRoot/api/CMSUserData';
	import BibliographyEditor from 'appRoot/vues/client/controls/BibliographyEditor.vue';
	import CmsEditField from 'appRoot/vues/client/controls/CmsEditField.vue';
	import CmsLifecycleState from 'appRoot/vues/client/controls/CmsLifecycleState.vue';
	import SelectImageControl from 'appRoot/vues/common/controls/SelectImageControl.vue';
	import SavableRegion from 'appRoot/vues/common/controls/SavableRegion.vue';
	import { TextInputDialog, ModalConfirmDialog, ModalMergeConflictsDialog, CmsProductLinksDialog, ImageOptimizerDialog, LoECreatingDialog, ProgressDialog, ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
	import { HTMLToText, ApplyObjectPatches, GetFileNameWithoutExtension, PreprocessBibliographyRecord, DefineReactiveNonEnumerableProperty } from 'appRoot/scripts/Util';
	import PageStateMonitoringMixin from 'appRoot/scripts/PageStateMonitoringMixin';
	import AbandonChangesMixin from 'appRoot/scripts/AbandonChangesMixin';
	import svg1 from 'appRoot/images/sprite/upload.svg';
	import svg2 from 'appRoot/images/sprite/expand_more.svg';
	import svg3 from 'appRoot/images/sprite/delete.svg';
	import svg4 from 'appRoot/images/sprite/delete_forever.svg';
	import svg5 from 'appRoot/images/sprite/publish.svg';
	import svg6 from 'appRoot/images/sprite/cable.svg';
	import svg7 from 'appRoot/images/sprite/cancel.svg';
	import svg8 from 'appRoot/images/sprite/history.svg';
	import svg9 from 'appRoot/images/sprite/replay.svg';
	import svg10 from 'appRoot/images/sprite/add.svg';
	import ISBN from 'isbn3';
	import SlideButton from 'appRoot/vues/common/controls/SlideButton.vue';
	import CmsProductHistorySelector from 'appRoot/vues/client/controls/CmsProductHistorySelector.vue';
	import StarredRowMixin from 'appRoot/scripts/StarredRowMixin.js';
	import EventBus from 'appRoot/scripts/EventBus';

	export default {
		components: { CmsLifecycleState, BibliographyEditor, SelectImageControl, CmsEditField, SavableRegion, SlideButton, CmsProductHistorySelector },
		mixins: [PageStateMonitoringMixin, AbandonChangesMixin, StarredRowMixin],
		props:
		{
			productRevisionId: {
				type: Number,
				required: true
			},
			raw_id_param: null
		},
		data()
		{
			return {
				appPath: appContext.appPath,
				publisherTypes: [],
				error: null,
				loading: false,
				saving: false,
				savingFlags: false,
				ImageDataUpload: null,
				productRevisionImageDownloadName: "",
				marc21RecordFileName: "",
				orig: {},
				productRevision: null,
				productRoot: null,
				revisionAutomationFlags: null,
				archivePendingOperation: false,
				archiveFileSize: null,
				archiveFileDate: null,
				archivePending: false,
				bibWarnings: null,
				showHistory: false,
			};
		},
		async created()
		{
			if (isNaN(this.productRevisionId) && typeof this.raw_id_param === "string" && this.raw_id_param)
			{
				let fxidMatch = this.raw_id_param.match(/fx(\d+)/i);
				if (fxidMatch)
				{
					let fxidString = fxidMatch[1];
					let fxid = parseInt(fxidString);
					this.loading = true;
					try
					{
						let data = await GetLiveCMSProductRevisionIdFromFxid(fxid);
						this.$router.replace({
							name: "clientCMSProductRevision",
							params: { id: data.productRevisionId }
						});
					}
					catch (err)
					{
						this.error = err;
					}
				}
			}
			else
				this.loadData();
		},
		computed:
		{
			stickyTopDynClasses()
			{
				let classes = [];
				if (this.productRevision)
					classes.push(this.productRevision.LifecycleState);
				return classes;
			},
			productImgSrc()
			{
				if (this.ImageDataUpload)
					return "data:image/*;base64," + this.ImageDataUpload;
				else
					return appContext.appPath + 'ProductImage/' + this.productRevision.ImageId;
			},
			originalProductRevisionJson()
			{
				return JSON.stringify(this.orig);
			},
			revisionJson()
			{
				return JSON.stringify(this.productRevision);
			},
			bibliographyDeletionCount()
			{
				let count = 0;
				if (this.productRevision && this.productRevision.Bibliography)
				{
					for (let i = 0; i < this.productRevision.Bibliography.length; i++)
					{
						if (this.productRevision.Bibliography[i].delete)
							count++;
					}
				}
				return count;
			},
			hasChanges()
			{
				if (this.bibliographyDeletionCount > 0)
					return true;
				return !!(this.productRevision && this.originalProductRevisionJson !== this.revisionJson);
			},
			recordChangedServerside()
			{
				return !this.saving && this.pageChangeState && this.productRevision && this.pageChangeState.ChangeId !== this.productRevision.ChangeId;
			},
			shouldReloadData()
			{
				return !!(this.recordChangedServerside && !this.hasChanges && !this.saving && !this.loading && !this.error && !this.savingFlags && !this.archivePendingOperation);
			},
			archiveFileName()
			{
				if (this.productRevision && this.productRevision.ArchiveFile)
					return this.productRevision.ArchiveFile.Name;
				return this.archiveNameCurrent; // From PageStateMonitoringMixin
			},
			isbnValidationResults()
			{
				let isbnErrors = [];
				if (this.productRevision.Bibliography)
				{
					for (let i = 0; i < this.productRevision.Bibliography.length; i++)
					{
						let record = this.productRevision.Bibliography[i];
						if (record.Type === "ISBN-10")
						{
							let txt = record.Value.trim();
							let isbn = ISBN.parse(txt);
							if (!isbn)
								isbnErrors.push(record.Type + ' record value "' + txt + '" is not valid.');
							else if (txt !== isbn.isbn10h)
								isbnErrors.push(record.Type + ' record value "' + txt + '" should be "' + isbn.isbn10h + '"');
						}
						else if (record.Type === "ISBN-13" || record.Type === "EISBN-13")
						{
							let txt = record.Value.trim();
							let isbn = ISBN.parse(txt);
							if (!isbn)
								isbnErrors.push(record.Type + ' record value "' + txt + '" is not valid.');
							else if (txt !== isbn.isbn13h)
								isbnErrors.push(record.Type + ' record value "' + txt + '" should be "' + isbn.isbn13h + '"');
						}
					}
				}
				return isbnErrors;
			},
			LiveAfterStagingOnlineCompleted()
			{
				return this.revisionAutomationFlags ? this.revisionAutomationFlags.LiveAfterStagingOnlineCompleted : null;
			},
			star_ProductRootId()
			{
				return 0;
			},
			star_ProductRevisionIds()
			{
				return [this.productRevisionId];
			},
			imageChanged()
			{
				return !!this.ImageDataUpload || this.productRevision.ImageId !== this.orig.ImageId;
			}
		},
		methods:
		{
			async loadData()
			{
				this.loading = true;
				this.error = null;
				this.ImageDataUpload = null;
				this.productRevisionImageDownloadName = "";
				this.marc21RecordFileName = "";
				try
				{
					let data = await GetCMSProductRevision(this.productRevisionId);
					if (data.success)
						this.processProductRevisionFromServer(data);
					else
						this.error = data.error;
				}
				catch (err)
				{
					this.error = err;
				}
				finally
				{
					this.loading = false;
				}
			},
			processProductRevisionFromServer(data)
			{
				this.ImageDataUpload = null;
				this.productRevisionImageDownloadName = "";
				this.marc21RecordFileName = "";
				if (!data.productRevision)
					this.error = "The product revision was not found.";
				else if (!data.productRoot)
					this.error = "The product revision's root product was not found.";
				else
				{
					this.publisherTypes = data.publisherTypes;
					this.$store.commit("SetBibliographyTypes", data.bibliographyTypes);
					this.productRoot = data.productRoot;
					this.orig = JSON.parse(JSON.stringify(data.productRevision));
					if (data.productRevision.Bibliography)
					{
						// Add non-enumerable properties to bibliography records for change tracking (won't be serialized by JSON.stringify)
						for (let i = 0; i < data.productRevision.Bibliography.length; i++)
						{
							PreprocessBibliographyRecord(data.productRevision.Bibliography[i], i);
						}
					}
					this.productRevision = data.productRevision;
					this.revisionAutomationFlags = data.revisionAutomationFlags;
					this.productRevisionImageDownloadName = "r" + this.productRevision.ProductRevisionId.toString();
					this.marc21RecordFileName = GetFileNameWithoutExtension(this.productRoot.FileName) + ".mrc";
					this.archiveFileSize = data.archiveFileSize;
					this.archiveFileDate = data.archiveFileDate;
					this.archivePending = data.archivePending;
					if (this.bibWarnings)
					{
						if (this.bibWarnings.length > 0 && data.bibWarnings.length == 0)
							toaster.success("Bibliography Warnings", "Bibliography Warnings have been resolved.");
						else if (JSON.stringify(this.bibWarnings) !== JSON.stringify(data.bibWarnings))
							toaster.warning("Bibliography Warnings", "Bibliography Warnings have changed.");
					}
					this.bibWarnings = data.bibWarnings;
					if (data.starred)
					{
						let map = {};
						map[this.productRevision.ProductRevisionId] = this.productRevision;
						EventBus.myStarredProductRevisions = map;
					}
				}
				this.pageChangeState = null;
				this.checkPageState();
			},
			newImageSelected(arg)
			{
				this.ImageDataUpload = arg.base64;
				this.productRevisionImageDownloadName = "r" + this.productRevision.ProductRevisionId.toString() + arg.ext;
				this.productRevision.ImageId = 0;
				this.OpenImageOptimizerDialog(arg.base64);
			},
			async OpenImageOptimizerDialog(imageBase64)
			{
				let result = await ImageOptimizerDialog(imageBase64);
				if (result)
				{
					if (result.image)
					{
						this.ImageDataUpload = result.image.DataBase64;
						this.productRevisionImageDownloadName = "r" + this.productRevision.ProductRevisionId.toString() + result.image.FileExtension;
						this.productRevision.ImageId = 0;
					}
					else
					{
						this.resetProductImage();
					}
				}
			},
			resetProductImage()
			{
				this.ImageDataUpload = null;
				this.productRevisionImageDownloadName = "r" + this.productRevision.ProductRevisionId.toString();
				this.productRevision.ImageId = this.orig.ImageId;
			},
			GetRouteToProductRoot(productRoot)
			{
				return {
					name: "clientCMSProductRoot",
					params: { id: productRoot.ProductRootId }
				};
			},
			async saveRevision()
			{
				if (!this.hasChanges)
					return;
				if (this.loading || this.saving)
				{
					toaster.error("This page is already busy with something else.");
					return;
				}
				let updatedRev = JSON.parse(JSON.stringify(this.productRevision));
				let bibMissing = false;
				for (let i = this.productRevision.Bibliography.length - 1; i >= 0; i--)
				{
					let record = this.productRevision.Bibliography[i];
					if (record.delete || (!record.Type && !record.Value))
						updatedRev.Bibliography.splice(i, 1);
					else if (!record.Type || !record.Value)
					{
						bibMissing = true;
						record.shake = true;
						setTimeout(() => { record.shake = false }, 500);
					}
				}
				if (bibMissing)
				{
					toaster.warning("Bibliography field(s) are missing.");
					return;
				}
				this.saving = true;
				let data = await UpdateCMSProductRevision(this.orig, updatedRev, this.ImageDataUpload);
				try
				{
					if (data.success)
						this.processProductRevisionFromServer(data);
					else
					{
						if (data.mergeConflicts)
						{
							let closeArgs = await ModalMergeConflictsDialog(data.mergeConflicts);
							return this.ApplyMergeResults(closeArgs);
						}
						else
							toaster.error(data.error);
					}
				}
				catch (err)
				{
					toaster.error(err);
				}
				finally
				{
					this.saving = false;
				}
			},
			async onDeleteRevision()
			{
				if (this.loading || this.saving)
				{
					toaster.error("This page is already busy with something else.");
					return;
				}
				let inputResult = await TextInputDialog("Confirm Deletion", "You are about to delete product revision " + this.productRevision.ProductRevisionId + ":\n\n"
					+ HTMLToText(this.productRevision.RevisionNameHtml) + "\n\n"
					+ "Type this revision's ID to confirm:", "Revision ID");
				if (inputResult)
				{
					if (inputResult.value === this.productRevision.ProductRevisionId.toString())
					{
						this.loading = true;
						this.error = null;
						try
						{
							let data = await DeleteCMSProductRevision(this.productRevision.ProductRevisionId);
							if (data.success)
							{
								this.$router.push({
									name: "clientCMSProductRoot",
									params: { id: this.productRevision.ProductRootId }
								});
							}
							else
							{
								toaster.error(data.error);
								this.loading = false;
							}
						}
						catch (err)
						{
							toaster.error(err);
							this.loading = false;
						}
					}
					else
					{
						toaster.info("User-entered Product Revision ID did not match.");
					}
				}
			},
			async deleteArchiveFile()
			{
				let confirmResult = await ModalConfirmDialog("Confirm you want to delete this revision's title archive.", "Confirm Archive Deletion", "Delete", "Cancel");
				if (!confirmResult)
					return;
				if (this.loading || this.saving)
				{
					toaster.error("This page is already busy with something else.");
					return;
				}
				this.saving = true;
				try
				{
					let data = await CMSDeleteArchive(this.productRevision.ProductRevisionId);
					if (data.success)
					{
						if (!this.hasChanges)
							this.processProductRevisionFromServer(data);
						else
						{
							this.productRevision.ArchiveFile = null;
							this.orig.ArchiveFile = null;
						}
					}
					else
					{
						toaster.error(data.error);
						this.loading = false;
					}
				}
				catch (ex)
				{
					toaster.error(ex);
				}
				finally
				{
					this.saving = false;
				}
			},
			async cancelArchiveFile()
			{
				this.archivePendingOperation = true;
				try
				{
					let data = await CMSCancelArchiving(this.productRevisionId);
					if (data.success)
					{
						this.archivePending = false;
					}
					else
						toaster.error(data.error);
				}
				catch (err)
				{
					toaster.error(err);
				}
				finally
				{
					this.archivePendingOperation = false;
				}
			},
			async addArchiveFile(stageAutomatically)
			{
				this.archivePendingOperation = true;
				try
				{
					let data = await CMSBeginArchiving(this.productRevisionId, stageAutomatically);
					if (data.success)
					{
						this.archivePending = true;
					}
					else
						toaster.error(data.error);
				}
				catch (err)
				{
					toaster.error(err);
				}
				finally
				{
					this.archivePendingOperation = false;
				}
			},
			ApplyMergeResults({ mergeResults })
			{
				if (mergeResults)
				{
					ApplyObjectPatches(this, this.orig, this.productRevision, mergeResults);
					// It should be safe to load "their" changes into rows where "you" haven't changed anything, but that is a lot of extra complexity so it doesn't happen yet.
				}
			},
			async onLifecycleStateChange({ newState })
			{
				if (this.hasChanges)
				{
					toaster.warning("You can't change the Lifecycle State while there are other unsaved changes.");
					return;
				}
				if (this.loading || this.saving)
				{
					toaster.error("This page is already busy with something else.");
					return;
				}
				this.saving = true;
				try
				{
					let data = await ChangeCMSProductRevisionLifecycleState(this.productRevision, newState);
					if (data.success)
						this.processProductRevisionFromServer(data);
					else
						this.error = data.error;
				}
				catch (err)
				{
					this.error = err;
				}
				finally
				{
					this.saving = false;
				}
			},
			OpenCmsProductLinksDialog()
			{
				CmsProductLinksDialog(this.productRoot, this.productRevision).then(result => { });
			},
			HistoryItemSelected(arg)
			{
				// This is brittle code, I'm aware, and I'm sorry.
				jset(arg.obj, this.productRevision, "CmsConfig");
				jset(arg.obj, this.productRevision, "ImageId");
				jset(arg.obj, this.productRevision, "Marc21Record");
				jset(arg.obj, this.productRevision, "PublicMetadata");
				jset(arg.obj, this.productRevision, "PublisherName");
				jset(arg.obj, this.productRevision, "RevisionNameHtml");

				// Merging in the bibliography in a way that tracks changes nicely is not trivial:
				if (arg.obj.Bibliography)
				{
					for (let i = 0; i < arg.obj.Bibliography.length; i++)
					{
						let newBib = arg.obj.Bibliography[i];
						if (this.productRevision.Bibliography.length <= i)
						{
							// Add new key/value
							let record = PreprocessBibliographyRecord({ Type: "", Value: "" }, this.productRevision.Bibliography.length);
							DefineReactiveNonEnumerableProperty(record, "newRow", true);
							record.Type = newBib.Type;
							record.Value = newBib.Value;
							this.productRevision.Bibliography.push(record);
						}
						else
						{
							// Update existing key/value
							let formBib = this.productRevision.Bibliography[i];
							formBib.Type = newBib.Type;
							formBib.Value = newBib.Value;
							formBib.delete = false;
						}
					}
					// Remove key/values that shouldn't be here anymore.
					for (let i = arg.obj.Bibliography.length; i < this.productRevision.Bibliography.length; i++)
					{
						this.productRevision.Bibliography[i].delete = true;
					}
				}
				else
					this.productRevision.Bibliography = arg.obj.Bibliography;
			},
			async createLoeFromThisRevision(revId)
			{
				if (this.hasChanges)
				{
					toaster.warning("Please save your changes first.");
					return;
				}
				LoECreatingDialog(this.productRoot.TaylorProductId, this.productRevisionId, this.productRevision.ImageId);
			},
		},
		watch:
		{
			archiveNameCurrent()
			{
				// When the current product revision's archival process is complete...
				if (!this.hasChanges && this.archiveNameCurrent && this.productRevision && !this.productRevision.ArchiveFile)
					this.loadData();
			},
			error()
			{
				if (this.error)
				{
					this.orig = {};
					this.productRoot = null;
					this.productRevision = null;
				}
			},
			async LiveAfterStagingOnlineCompleted(oldValue, newValue)
			{
				if (oldValue !== null && newValue !== null)
				{
					this.savingFlags = true;

					try
					{
						let data = await CMSSetRevisionAutomationFlags(this.productRevisionId, null, this.LiveAfterStagingOnlineCompleted);
						if (!data.success)
							toaster.error(data.error);
					}

					finally
					{
						this.savingFlags = false;
					}
				}
			},
			shouldReloadData()
			{
				if (this.shouldReloadData)
					this.loadData();
			},
			productRevisionId()
			{
				this.loadData();
			}
		}
	};
	function jset(src, target, name)
	{
		target[name] = src[name];
	}
</script>

<style>
	/* unscoped */
	.inactiveUserName
	{
		opacity: 0.48;
	}
</style>
<style scoped>
	.pageRoot
	{
	}

		.pageRoot > *
		{
			padding-bottom: 8px;
		}

	.center
	{
		text-align: center;
	}

	.inTrash
	{
		color: #440000;
		background-color: #FFF6E1;
	}

	.trashContainer
	{
		margin-bottom: 1em;
	}

	.trash
	{
		display: inline-flex;
		align-items: center;
		font-weight: bold;
		background-color: rgba(255,245,180,1);
		border: 1px solid rgba(200,165,0,1);
		border-radius: 3px;
		padding: 3px 13px 3px 8px;
		color: #AA0000;
	}

	.trash_img
	{
		flex: 1 0 auto;
		fill: currentColor;
		height: 2em;
		width: 2em;
		margin-right: 0.5em;
	}

	.SidePadding
	{
		padding: 0px 8px;
		padding-top: 1em;
	}

	.stickyTop
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		background-color: #FFFFFF;
		z-index: 4; /* Slider Buttons otherwise overlap */
		top: 0px;
		height: 34px;
		padding: 2px 8px 4px 8px;
		border-bottom: 1px solid #000000;
		box-shadow: rgba(0,0,0,0.4) 0px 1px 1px 1px;
	}

		.stickyTop h3
		{
			margin: 0px;
			flex: 1 1 auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

	.backButton
	{
		display: inline-block;
		margin-right: 8px;
		margin-bottom: 2px;
		margin-top: 2px;
		border: 1px solid #888888;
		border-radius: 5px;
		font-size: 0px;
		vertical-align: text-bottom;
		background-color: #F6F6F6;
		box-shadow: rgba(0,0,0,0.4) 1px 1px 1px 1px;
	}

		.backButton:hover
		{
			background-color: #FFFFFF;
		}

		.backButton svg
		{
			display: inline-block;
			width: 21px;
			height: 21px;
			transform: rotate(90deg);
			fill: #0000FF;
		}

	.stickyTop.Upcoming
	{
		background-color: rgb(255 207 180);
	}

	.stickyTop.Staging
	{
		background-color: rgb(255 245 180);
	}

	.stickyTop.Live
	{
		background-color: rgb(132 255 132);
	}

	.stickyTop.Inactive
	{
		background-color: rgb(217 241 255);
	}

	.SaveButton
	{
		display: inline-flex;
		align-items: center;
		border: 1px solid #888888;
		border-radius: 5px;
		height: 34px;
		box-sizing: border-box;
		padding: 0px 1em;
		font-size: 1.1em;
		cursor: pointer;
		background-color: #DDFFDD;
		box-shadow: rgba(0,0,0,0.4) 1px 1px 1px 1px;
		animation: SaveBackground 1s steps(2, end) infinite;
	}

	@keyframes SaveBackground
	{
		from
		{
			background-color: #DDFFDD;
		}

		to
		{
			background-color: #88FF88;
		}
	}

	.SaveButton:hover
	{
		background-color: #44FF44;
		animation: none;
	}

	.stickyTop.Live .SaveButton
	{
		background-color: #FFDDDD;
		animation: SaveBackgroundLive 1s steps(2, end) infinite;
	}

	@keyframes SaveBackgroundLive
	{
		from
		{
			background-color: #FFDDDD;
		}

		to
		{
			background-color: #FF8888;
		}
	}

	.stickyTop.Live .SaveButton:hover
	{
		background-color: #FF4444;
		animation: none;
	}

	.SaveButton.disabled,
	.stickyTop.Live .SaveButton.disabled
	{
		background-color: #DDDDDD !important;
		color: #999999;
		animation: none !important;
		cursor: inherit;
	}

	.TopFields
	{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.FxMediaField
	{
		position: relative;
		border: 1px solid black;
		box-shadow: rgba(0,0,0,0.4) 2px 2px 2px 1px;
		width: 260px;
		background-color: #FFFFCC;
	}

		.FxMediaField.assigned
		{
			background-color: #CCFFCC;
		}

		.FxMediaField .EditFieldTitle
		{
			text-align: center;
			font-weight: bold;
			margin-bottom: 0px;
			padding: 4px;
			background-color: var(--primary-color);
			color: var(--text-color-on-primary-color);
		}

		.FxMediaField .EditFieldValue
		{
			text-align: center;
			padding: 4px;
		}

		.FxMediaField .buttonBarButton
		{
			margin: 6px 0px;
		}

		.FxMediaField .slideToStage
		{
			margin: 6px 0px;
		}

			.FxMediaField .slideToStage::v-deep .slideunlock-text
			{
				font-size: 20px;
				line-height: 28px;
			}

	.ProductImageEditField
	{
		margin-right: 1em;
	}

	.ProductImage
	{
		max-width: 140px;
		height: 140px;
	}

	.download_img
	{
		fill: currentColor;
		height: 1em;
		width: 1em;
		vertical-align: text-bottom;
		transform: rotate(180deg);
	}

	/*	.FloatingBelow
	{
		position: sticky;
		z-index: 1;
		top: 45px;
		left: 100%;
		margin-top: 4px;
		margin-right: 8px;
		width: fit-content;
		background-color: #f3f3f3;
		border: 1px solid #000000;
		border-radius: 5px;
		padding: 5px;
		font-size: 0.9em;
		font-family: Consolas, monospace;
		box-shadow: rgba(0,0,0,0.4) 0px 1px 1px 1px;
	}*/

	.FloatingBelow
	{
		float: right;
		border: 1px solid #000000;
		border-radius: 5px;
		padding: 5px;
		background-color: #f3f3f3;
		font-size: 0.9em;
		font-family: Consolas, monospace;
		position: absolute;
		top: calc(100% + 10px);
		right: 8px;
		max-width: calc(50vw - 74px);
	}

	.page_state_unavailable_img
	{
		width: 17px;
		height: 17px;
		fill: #FF0000;
		vertical-align: bottom;
	}

	.PublicSiteFields
	{
		border: 1px solid #000000;
		border-radius: 4px;
		margin-top: 2.5em;
		padding: 0.75em 1em 0.33em 1em;
		/*background-color: #EEEEEE;*/
		position: relative;
		/*box-shadow: rgba(36,91,199,0.2) 0px 0px 10px 5px inset;*/
		box-shadow: rgba(99,99,99,0.2) 0px 0px 10px 5px inset;
	}

	.PublicSiteFieldsLabel
	{
		display: inline-block;
		position: absolute;
		top: -12px;
		background-color: #FFFFFF;
		border: 1px solid #000000;
		padding: 1px 7px;
		font-size: 1.2em;
		font-weight: bold;
		/*cursor: pointer;*/
	}

	/*.PublicSiteFieldsLabel:hover,
		.PublicSiteFieldsLabel:focus
		{
			background-color: #FFFFDD;
			outline: 1px solid rgba(0,0,255,0.5);
		}*/

	.bibWarnings
	{
		margin-top: 1em;
		color: #AA0000;
	}

	.CmsConfigFields
	{
		border: 1px solid #000000;
		border-radius: 4px;
		margin-top: 2.5em;
		padding: 0.75em 1em 0.33em 1em;
		/*background-color: #EEEEEE;*/
		position: relative;
		/*box-shadow: rgba(36,91,199,0.2) 0px 0px 10px 5px inset;*/
		box-shadow: rgba(99,99,99,0.2) 0px 0px 10px 5px inset;
	}

	.CmsConfigFieldsLabel
	{
		display: inline-block;
		position: absolute;
		top: -12px;
		background-color: #FFFFFF;
		border: 1px solid #000000;
		padding: 1px 7px;
		font-size: 1.2em;
		font-weight: bold;
		/*cursor: pointer;*/
	}

	.historySelectorContainer
	{
		margin-top: 1em;
	}

	.resetBtn
	{
		width: 18px;
		height: 18px;
		vertical-align: text-top;
		cursor: pointer;
	}

		.resetBtn:hover,
		.resetBtn:focus
		{
			background-color: rgba(0,0,0,0.2);
			outline: 1px solid rgba(0,0,255,0.5);
		}
</style>